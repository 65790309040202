<template>
    <div v-if="$store.state.isModalOpenTwo" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ 'Add Patient' }}</h1>
                            <button
                                @click="onClickClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">
                
                <div class="pb-2">
                    
                    <div class="col-11 py-1 mx-auto">
                        <div class="mb-1">
                            <label class="form-label" for="fullName"> <span class="required">*</span> Full Name</label>
                            <input
                                name="amount" @input="formError.full_name = ''"
                                type="text" v-model.trim="formData.full_name"
                                class="form-control"
                            />
                            <transition name="fade">
                                <span v-if="formError.full_name" class="invalid-feedback d-block qcont">{{ formError.full_name }}</span>
                            </transition>
                        </div>

                        <div class="mb-1">
                            <label class="form-label" for="mobileNo"> <span class="required">*</span> Mobile No (017xxxxxxxx)</label>
                            <input
                                name="mobile" @input="formError.mobile_no = ''"
                                type="text" v-model.trim="formData.mobile_no"
                                class="form-control"
                            />
                            <transition name="fade">
                                <span v-if="formError.mobile_no" class="invalid-feedback d-block qcont">{{ formError.mobile_no }}</span>
                            </transition>
                            <transition name="fade">
                                <span v-if="formError.birthday" class="invalid-feedback d-block qcont">{{ formError.birthday }}</span>
                            </transition>
                            <transition name="fade">
                                <span v-if="formError.gender" class="invalid-feedback d-block qcont">{{ formError.gender }}</span>
                            </transition>
                        </div>

                        

                        <div class="d-flex justify-content-between">

                            <div class="mb-1 col-6">
                                <label class="form-label" for="birthday"> <span class="required">*</span> Birthday</label>
                                <input
                                    name="date" @change="getBirthDayCal"
                                    type="date" v-model="formData.birthday"
                                    class="form-control"
                                />
                            </div>

                            <div class="mb-1 col-5">
                                <label class="form-label" for="gender"> <span class="required">*</span> Gender</label>
                                <v-select
                                    placeholder="Select Gender"
                                    :options="formattedGenders" 
                                    v-model="formData.gender"
                                    :reduce="name => name.id"
                                    label="name"
                                />
                            </div>
                        </div>

                        <div class="d-flex justify-content-between">
                            <div class="mb-1 col-4">
                                <label class="form-label" for="birthday">Age in year</label>
                                <input 
                                    type="text" @input="getAgeCal"
                                    id="age_year" class="form-control" 
                                    v-model="formData.age_year"
                                    placeholder="Year"
                                />
                            </div>
                            <div class="mb-1 col-4">
                                <label class="form-label" for="birthday">Age in month</label>
                                <input 
                                    max="12" 
                                    type="text" id="age_month" 
                                    class="form-control" @input="getAgeCal"
                                    v-model="formData.age_month" placeholder="Month"
                                />
                            </div>
                            <div class="mb-1 col-4">
                                <label class="form-label" for="birthday">Age in day</label>
                                <input 
                                    max="31" @input="getAgeCal" 
                                    type="text" id="age_day"
                                    class="form-control"
                                    v-model="formData.age_day" 
                                    placeholder="Day"
                                />
                            </div>
                        </div>

                        <div class="mb-1">
                            <label class="form-label" for="gender">Referrer</label>
                            <v-select
                                placeholder="Select Referrer"
                                :options="doctors"
                                :reduce="name => name.id"
                                label="name" v-model="formData.referred_by"
                            />
                        </div>
                        
                    </div>

                    <div class="col-12 text-center">
                        <button :disabled="loading" @click="submit" type="button" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Submit
                        </button>
                        <button @click.prevent="onClickClose" class="btn btn-outline-secondary waves-effect">
                            Cancel
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
import handleChartAccount from '@/services/modules/chartAccount'
import Loader from "@/components/atom/LoaderComponent";
import handleRole from "@/services/modules/role";
import handleHospital from "@/services/modules/hospital";
import handleCompanyContact from "@/services/modules/companyContact";
import { computed, inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';

const props = defineProps(['doctors', 'onCreateProfile']);
const emit = defineEmits(['onCreateProfile']);
const $store = useStore();
const $route = useRoute();
const modalRef = ref(null);
const loading = ref(false);
const loader = ref(false);
const {fetchHome} = handleChartAccount()
const {fetchCompanyDefaultRoles} = handleRole();
const { storeCompanyContact, storeAdditionalContactItems } = handleCompanyContact();
const {dateOfBarthConvertToAge} = handleHospital()
const genders= ref({});
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const formattedGenders = computed(() => {
    return Object.keys(genders.value).map(key => ({ id: key, name: genders.value[key] }));
})

const formData = reactive({
    full_name: null,
    mobile_no: null,
    birthday: null,
    age_year: null,
    age_month: null,
    age_day: null,
    gender: null,
    referred_by: null,
    company_rules: [],
    contact_id: '',
    contact_type: 'person',
    active_tab: 'basic-info',
    profile_type: 'user',
})

const formError = reactive({
    full_name: '',
    mobile_no: '',
    birthday: '',
    gender: ''
})

const getBirthDayCal = () => {
  const {year, month, day} = dateOfBarthConvertToAge(formData.birthday)
  formData.age_year = year
  formData.age_month = month
  formData.age_day = day
}

const getAgeCal = () => {
  validateAgeMonth();
  validateAgeDay();

  const currentDate = new Date();

  const years = formData.age_year || 0;
  const months = formData.age_month || 0;
  const days = formData.age_day || 0;

  const calculatedDate = new Date(
      currentDate.getFullYear() - years,
      currentDate.getMonth() - months,
      currentDate.getDate() - days
  );
  formData.birthday = calculatedDate.toISOString().split('T')[0];
};

const validateAgeMonth = () => {
  const maxMonth = 12;
  if (formData.age_month > maxMonth) {
    formData.age_month = maxMonth.toString();
  }
};

const validateAgeDay = () => {
  const maxDay = 31;
  if (formData.age_day > maxDay) {
    formData.age_day = maxDay.toString();
  }
};

//Functions and methods
const submit = async() => {
    if(checkValidation()) return;
    const query = getQuery();
    loading.value

    await storeCompanyContact(formData, query).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message)
        const {mobile_no, referred_by} = formData
        const resData = res.data;
        saveAdditionalContactItems(res.data.id, { mobile_no, referred_by }, query)
        resData.mobile_no = mobile_no;

        emit('onCreateProfile', resData);
        onClickClose();
        showSuccess(res.message)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const saveAdditionalContactItems = async (contactId, keys, getQuery) => {
  await storeAdditionalContactItems(contactId, keys, getQuery).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
    // updateFormData.value = {...updateFormData.value, ...res.data};
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const checkValidation = () => {
    let flag = false;

    if(!formData.full_name){
        formError.full_name = 'Full name is required'
        flag = true;
    }

    if(!formData.mobile_no){
        formError.mobile_no = 'Mobile no is required'
        flag = true;
    }

    if(!formData.birthday){
        formError.birthday = 'Birthday is required'
        flag = true;
    }

    if(!formData.gender){
        formError.gender = 'Gender is required'
        flag = true;
    }

    return flag;
}

const onClickClose = () => {
    $store.state.isModalOpenTwo = false;
}

useDetectOutsideClick(modalRef, () => {
    // onClickClose();
})

function getQuery() {
  return `?company_id=${$route.params.companyId}`;
}

onMounted(() => {
  const companyQuery = `?company_id=${$route.params.companyId}&roles=patient`

  Promise.all([
    fetchCompanyDefaultRoles(companyQuery).then((res) => {
        if(res.data) {
            formData.company_rules = res.data.map(item => item.id);
        }
    }),
    fetchHome().then(res => {
      if (res.data) {
        genders.value = res.data.genders
      }
    })
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  })

})
</script>